<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration"  dur="3"  />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">BMI与成绩分析统计</div>
          </div>

<div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>
  <!-- 年级 -->
  <div class="classInfo">
    <div class="classInfo_Item" v-for="(item,index) in array" :key="index">
      <dv-border-box-13>
        <h3 class="title fixed1">{{item.title}}</h3>
        <div class="classInfo_Item_con">
 <dv-scroll-board class="dv-scr-board" :config="item.config" />
         
          
        </div>
      </dv-border-box-13>
      
    </div>
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      array: [{title: '一年级BMI与成绩分析',
      config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['张良','110cm', '40kg',"19.8", "93"],
          ['张兰','115cm', '42kg',"19.8", "96"],
          ['康宇轩','120cm', '41kg',"19.8", "90"],
          ['刘晨皓', '130cm', '48kg',"19.8", "86"],
          ['任子川', '118cm', '42kg',"19.8", "83"],
          ['王浩嘉', '123cm', '41kg',"19.8", "78"],
          ['王文谦', '123cm', '43kg',"19.8", "99"],
          ['魏梓晨','118cm', '42kg',"19.8", "88"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','left'],
        columnWidth: [100],
      }},{title: '二年级BMI与成绩分析',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['王文胤', '110cm', '40kg',"19.8", "93"],
          ['陈心璇', '110cm', '40kg',"19.8", "93"],
          ['安逸', '110cm', '40kg',"19.8", "93"],
          ['陈心璇','110cm', '40kg',"19.8", "93"],
          ['戴熠默', '110cm', '40kg',"19.8", "93"],
          ['董佳琪', '110cm', '40kg',"19.8", "93"],
          ['韩尚希','110cm', '40kg',"19.8", "93"],
          ['何姝菡','110cm', '40kg',"19.8", "93"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [100],
      }},{title: '三年级BMI与成绩分析',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['胡蝶','110cm', '40kg',"19.8", "93"],
          ['李朵', '110cm', '40kg',"19.8", "93"],
          ['李牧瑶','110cm', '40kg',"19.8", "93"],
          ['李奕诺','110cm', '40kg',"19.8", "93"],
          ['任子川', '110cm', '40kg',"19.8", "93"],
          ['赵梓潼', '110cm', '40kg',"19.8", "93"],
          ['申钰淇','110cm', '40kg',"19.8", "93"],
          ['赵奕莹', '110cm', '40kg',"19.8", "93"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [100],
      }},{title: '四年级BMI与成绩分析',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['魏祎', '110cm', '40kg',"19.8", "93"],
          ['许梦棋', '110cm', '40kg',"19.8", "93"],
          ['薛善溪', '110cm', '40kg',"19.8", "93"],
          ['杨谨帆', '110cm', '40kg',"19.8", "93"],
          ['陈语萌', '110cm', '40kg',"19.8", "93"],
          ['刘仕娜', '110cm', '40kg',"19.8", "93"],
          ['王文谦', '110cm', '40kg',"19.8", "93"],
          ['赵梓瑶', '110cm', '40kg',"19.8", "93"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [100],
      }},{title: '五年级BMI与成绩分析',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['陈默', '110cm', '40kg',"19.8", "93"],
          ['高子瑾', '110cm', '40kg',"19.8", "93"],
          ['高彬泽', '110cm', '40kg',"19.8", "93"],
          ['郭鑫垚', '110cm', '40kg',"19.8", "93"],
          ['田佳浩', '110cm', '40kg',"19.8", "93"],
          ['李嘉毅', '110cm', '40kg',"19.8", "93"],
          ['王浩阳', '110cm', '40kg',"19.8", "93"],
          ['郭静溪', '110cm', '40kg',"19.8", "93"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [100],
      }},
      {title: '六年级BMI与成绩分析',config: {
        header: ['<span style="color:#9abeff;font-weight:bold">姓名</span>', 
        '<span style="color:#9abeff;font-weight:bold">平均身高</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均体重</span>', 
        '<span style="color:#9abeff;font-weight:bold">体质指数（BMI）</span>',
        '<span style="color:#9abeff;font-weight:bold">平均成绩</span>'],
        data: [
          ['赵晓峰', '160cm', '40kg',"19.8", "91"],
          ['张兰', '170cm', '40kg',"19.8", "90"],
          ['高彬泽', '13cm', '40kg',"19.8", "87"],
          ['张琪', '120cm', '40kg',"19.8", "78"],
          ['田佳浩','156cm', '40kg',"19.8", "99"],
          ['王浩阳', '160cm', '40kg',"19.8", "91"],
          ['高子瑾','154cm', '40kg',"19.8", "91"],
          ['郭静溪','132cm', '40kg',"19.8", "92"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 54,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
         align: ['left','left'],
        columnWidth: [100],
      }}
      ]
      
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

   
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },

     returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.title{
  padding: 30px 24px;
    font-size: 22px;
}
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}

.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 52px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}
  }

  
.classInfo{
  margin: 0 20px;
  >div:nth-child(3n){
    margin-right: 0;
  }
  >div:nth-child(-n+3){
    margin-bottom: 36px;
  }
  .classInfo_Item{
    width: 610px;
    height: 440px;
    float: left;
    font-size: 20px ;
    margin-right: 20px;
      .dv-scr-board {
       padding: 0 20px;
    width: 609px;
    height: 352px;
      color: #d4eaf6;
      ::v-deep .header-item{
        font-size: 18px !important;
      }
      ::v-deep .row-item{
        height: 58px !important;
        line-height:58px !important;
        font-size: 18px !important;
        color: #9abeff !important;
      }
      
    }
    
  }
}

}
</style>
